<template>
  <div style="height: 100%">
    <v-container v-if="loading" fill-height fluid>
      <v-row align="center" justify="center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>
    <v-carousel
        cycle
        height="100%"
        hide-delimiter-background
        show-arrows-on-hover
        v-if="slider"
        progress
        progress-color="primary"
        interval="5000"
    >
      <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="slide.image.data.full_url" :title="slide.title" eager>
        <!--v-container fill-height fluid>
          <v-row align="center" justify="center">
            <img :src="slide.image.data.full_url" :title="slide.title" class="headerImage"
                 lazy-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABLAAAAAyCAYAAABMFiIPAAABcklEQVR42u3YQQEAMAgAIU2+6K7GPSAGezNvAAAAACBqBRYAAAAAZQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaR+2qUrPM1FL2wAAAABJRU5ErkJggg==">
          </v-row>
        </v-container-->
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>

import client from "../directus";

const sortSlidesBySequence = function (a, b) {
  if ((!a || !a.sequence) && b && b.sequence) {
    return b.sequence;
  } else if ((!b || !b.sequence) && a && a.sequence) {
    return a.sequence;
  } else if (!a && !a.sequence && !b && !b.sequence) {
    if ((!a.image || !a.image.title) && b.image && b.title) {
      return 1;
    } else if ((!b.image || !b.image.title) && a.image && a.image.title) {
      return -1;
    }
    return a.image.title.localeCompare(b.image.title);
  }
  return a.sequence - b.sequence;
}

export default {
  name: 'Slider',
  props: {
    name: String,
  },
  data() {
    return {
      slides: [],
      loading: false,
      slider: null,
      error: null,
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.loadSlider()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'loadSlider'
  },
  methods: {
    async getSlider(name) {
      try {
        let result = await client.getItems('slider', {
          filter: {
            name: {
              eq: name
            }
          },
          fields: "*.*.*",
          single: true,
        });
        if (!result) {
          return [];
        }
        return result.data;
      } catch (e) {
        console.error(e);
      }
    },
    async loadSlider() {
      this.error = this.slider = null;
      this.loading = true;
      try {
        const data = await this.getSlider(this.name);
        if (!data) {
          this.slider = null;
          this.loading = false;
          this.error = "Leider nichts gefunden";
          return Promise.reject(this.error);
        }
        this.slider = data;
        this.slides = data.images.sort(sortSlidesBySequence);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = error;
        console.error("There was an error!", error);
      }
    },
  }
}
</script>

<style scoped>

.v-carousel .v-window-item {
  position: absolute;
  top: 0;
  width: 100%;
}

.headerImage {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
  vertical-align: bottom;
  border: 0;
}

</style>