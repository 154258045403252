<template>
  <v-card :color="getBackgroundColor(project)" dark>
    <v-container>
      <v-row class="ma-0 pa-0" no-gutters align="center">
        <v-col cols="12" sm="12" md="5" lg="5" xl="5" align-self="start" shrink>
          <h1 style="color: white">{{ project.name.toUpperCase() }}, {{ project.country.toUpperCase() }}</h1>
        </v-col>
        <v-col cols="12" sm="12" md="7" lg="7" xl="7" align-self="start" shrink>
          <h2 style="color: white;text-align: start" class="pa-0">{{ project.title.toUpperCase() }}</h2>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" justify="space-between" no-gutters align="center">
        <v-col cols="12" sm="12" md="5" lg="4" xl="4" align-self="start">
          <h3 style="color: white">Gesamtfinanzierung</h3>
        </v-col>
        <v-col cols="11" sm="9" md="5" lg="4" xl="4" align-self="center" class="d-flex justify-center">
          <h3 :class="getFinanceClass(project)">
            <span>{{ getCurrency(project.finance_value) }} | </span>
            <span class="percentageValue"> {{ getPercentage(project) }}</span>
            <span> | {{ getCurrency(project.finance_target) }}</span>
          </h3>
        </v-col>
        <v-col cols="1" sm="2" md="2" lg="2" xl="2" class="pa-2" shrink>
          <div class="d-flex justify-end pa-1">
            <v-btn color="secondary" small v-if="project.project_state !== 'Abgeschlossen'" class="d-none d-sm-block" @click="spendenClick">
              <v-icon small left>
                mdi-currency-eur
              </v-icon>
              Spenden
            </v-btn>
            <v-icon color="white" v-else>
              mdi-check
            </v-icon>
            <v-btn color="secondary" small icon dark v-if="project.project_state !== 'Abgeschlossen'"
                   class="d-md-none d-sm-none" to="/spenden">
              <v-icon>
                mdi-currency-eur
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="child-flex ma-0 mt-2" no-gutters align="center">
        <v-col cols="12" style="width: 100%; height: 1em">
          <v-progress-linear :value="calculatePercentage(project) * 100.0"
                             color="white"></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import {formatCurrency, formatPercentage} from "../utils/utils";

export default {
  name: 'project-detail-header',
  props: {
    project: {type: Object}
  },
  methods: {
    getCurrency(number) {
      return formatCurrency(number);
    },
    calculatePercentage(project) {
      return project.finance_value / project.finance_target;
    },
    getPercentage(project) {
      return formatPercentage(this.calculatePercentage(project));
    },
    getBackgroundColor(project) {
      return project.project_state === 'Abgeschlossen' ? 'secondary' : 'primary';
    },
    getFinanceClass(project) {
      return project.project_state === 'Abgeschlossen' ? 'financeValuesDone' : 'financeValues';
    },
    spendenClick() {
      // to Attribute messes with button styling - therefore we trigger navigation programmatically
      this.$router.push('/spenden');
    }
  },
}
</script>

<style scoped>

h4 {
  font-size: 14px;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  color: var(--v-primary-base) !important;
}

.theme--light.v-btn.v-btn--disabled .v-icon {
  color: var(--v-primary-base) !important;
}

.financeValues {
  color: black;
}

.financeValuesDone {
  color: var(--v-primary-base);
}

.percentageValue {
  color: white;
}

h3 {
  font-size: 1.3em;
}

h6 {
  color: var(--v-primary-base);
  font-size: 0.625em;
  padding-top: 8px;
}

</style>