<template>
  <div class="mainContent">
    <img id="page-header-image" v-if="header_image" :src="header_image.data.full_url" :title="header_image.title"
         class="headerImage"
         lazy-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABLAAAAAyCAYAAABMFiIPAAABcklEQVR42u3YQQEAMAgAIU2+6K7GPSAGezNvAAAAACBqBRYAAAAAZQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaR+2qUrPM1FL2wAAAABJRU5ErkJggg==">
    <v-container v-if="loading" fill-height fluid>
      <v-row align="center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>
    <v-container v-if="error" class="mt-2" fill-height fluid>
      <v-row align="center">
        <v-col>
          <div class="error">{{ error }}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="project">
      <v-row justify="space-around">
        <v-col sm="12" md="10" lg="10" xl="10" class="pa-0">
          <project-detail-header :project="project"/>
          <v-row class="child-flex ma-0">
            <div>
              <v-toolbar flat dense>
                <v-tabs color="primary" v-model="tab" show-arrows>
                  <v-tab key="home">Überblick</v-tab>
                  <v-tab key="project">Projekt</v-tab>
                  <v-tab key="details">Details</v-tab>
                  <v-tab key="implementation">Umsetzung</v-tab>
                  <v-tab key="photos" v-show="project.slider">Fotos</v-tab>
                </v-tabs>
              </v-toolbar>
            </div>
          </v-row>
          <v-row justify="space-around">
            <v-col sm="12" md="8" lg="8" xl="8">
              <v-tabs-items v-model="tab">
                <v-tab-item key="home">
                  <v-row class="ma-2">
                    <v-col>
                      <h3>PROJEKTBESCHREIBUNG</h3>
                      <div v-html="project.short_description" class="content"></div>
                    </v-col>
                  </v-row>
                  <v-row class="ma-2">
                    <v-col align-self="center">
                      <img id="map-image" v-if="project.map_image" :src="project.map_image.data.full_url"
                           :title="project.map_image.title"
                           class="contentImage">
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="project">
                  <v-row class="ma-2">
                    <v-col>
                      <div v-html="project.description" class="content"></div>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="details">
                  <v-row class="ma-2">
                    <v-col>
                      <div v-html="project.project" class="content"></div>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="implementation">
                  <v-row class="ma-2">
                    <v-col>
                      <div v-html="project.details" class="content"></div>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="photos">
                  <v-row class="ma-2" v-if="project.slider">
                    <v-col>
                      <slider :name="project.slider.name" class="project-gallery"></slider>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-col sm="12" md="4" lg="4" xl="4" class="pa-0">
              <v-row class="child-flex ma-0">
                <project-codes :project="project"/>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import client from '../directus';
import Slider from "../components/Slider";
import ProjectCodes from "../components/ProjectCodes";
import ProjectDetailHeader from "../components/ProjectDetailHeader";

export default {
  name: 'Project',
  components: {ProjectDetailHeader, ProjectCodes, Slider},
  data() {
    return {
      loading: false,
      project: null,
      error: null,
      header_image: null,
      tab: 0,
    };
  },
  computed: {
    // a computed getter
    hideProjectCodes: function () {
      console.log(this.tab);
      return this.tab === 4;
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.loadPage();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'loadPage',
  },
  methods: {
    async getProject(path) {
      try {
        let result = await client.getItems('project', {
          fields: '*, header_image.*.*, section.*.*, map_image.*.*, slider.*.*',
          single: true,
          filter: {
            path: {
              eq: path,
            },
          },
        });
        if (!result) {
          return null;
        }
        return result.data;
      } catch (e) {
        console.error(e);
      }
    },
    async loadPage() {
      this.error = this.project = null;
      this.loading = true;
      try {
        const project = await this.getProject(this.$route.params.path);
        if (project) {
          this.project = project;
          this.header_image = project.header_image;
        } else {
          this.project = null;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = error;
        console.error('There was an error!', error);
      }
    }
  },
};
</script>

<style>
.content p {
  font-size: 16px;
  line-height: 1.5em;
  font-family: 'Roboto', 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin: 50px 0 10px;
}

.content h3 {
  color: black;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 24px;
  font-weight: 500;
  margin: 50px 0 10px;
}

ol, ul {
  margin: 20px 0 20px;
}

ol li, ul li {
  color: black;
  font-size: 16px;
  font-family: 'Roboto', 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.4em;
}

.content h1 {
  text-transform: uppercase;
  font-size: 3em;
  margin: 0.67em 0;
}

.content h1, h2{
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  /*color: #f7a600;*/
  color: black;
}

.content h2 {
  font-weight: 600;
  font-size: 1.8em;
}

.content .h1, .h2, .h3, h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

</style>

<style scoped>
.mainContent {
  min-height: 100%;
  max-width: 100%;
}

.headerImage {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
  vertical-align: bottom;
  border: 0;
}

.contentImage {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  border: 0;
}

h6 {
  color: var(--v-primary-base);
  font-size: 0.625em;
  padding-top: 8px;
}

@media screen and (min-width: 1905px) {
  .project-gallery {
    height: 800px !important;
    width: 100%;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1904px) {
  .project-gallery {
    height: 600px !important;
    width: 100%;
  }
}

@media screen and (min-width: 1265px) and (max-width: 1440px) {
  .project-gallery {
    height: 500px !important;
    width: 100%;
  }
}

@media screen and (min-width: 961px) and (max-width: 1264px) {
  .project-gallery {
    height: 400px !important;
    width: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .project-gallery {
    height: 300px !important;
    width: 90vw;
  }
}

@media screen and (max-width: 600px) {
  .project-gallery {
    height: 300px !important;
    width: 90vw;
  }
}

</style>